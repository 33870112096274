import React, { createContext, useContext, useState, ReactNode, useEffect } from "react";

type Timezone = {
    label: string;
    value: string;
};

type TimezoneContextType = {
    timezone: Timezone;
    setTimezone: (timezone: Timezone) => void;
};

const TimezoneContext = createContext<TimezoneContextType | undefined>(undefined);

export const TimezoneProvider = ({ children }: { children: ReactNode }) => {
    const defaultTimezone = { label: "UTC", value: "+0000" };
    const [timezone, setTimezone] = useState<Timezone>(() => {
        const storedTimezone = localStorage.getItem("selectedTimezone");
        return storedTimezone ? JSON.parse(storedTimezone) : defaultTimezone;
    });

    useEffect(() => {
        localStorage.setItem("selectedTimezone", JSON.stringify(timezone));
    }, [timezone]);

    return (
        <TimezoneContext.Provider value={{ timezone, setTimezone }}>
            {children}
        </TimezoneContext.Provider>
    );
};

export const useTimezone = () => {
    const context = useContext(TimezoneContext);
    if (!context) {
        throw new Error("useTimezone must be used within a TimezoneProvider");
    }
    return context;
};