export const languageSelectStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        color: "#2A2A2A",
        backgroundColor: state.isSelected ? "#F3F3F3" : "#FFFFFF",
        ":hover": {
            backgroundColor: state.isSelected ? "#F3F3F3" : "#DDE8F8",
            color: "#2A2A2A",
        },
        cursor: "pointer",
    }),
    input: (provided: any) => ({
        ...provided,
        color: "#FFFFFF", // Set the text color to white
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "#FFFFFF",
        width: "fit-content",
        minWidth: "auto",
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        padding: "0px 0px",
        color: "#FFFFFF",
        backgroundColor: "#222222",
        border: "none",
        boxShadow: state.menuIsOpen ? "0 0 0 1px #FFFFFF" : "none", // Change this to your desired border color
        ":hover": {
            borderColor: state.menuIsOpen ? "#FFFFFF" : provided.borderColor,
        },
        width: "fit-content",
        height: "fit-content",
        cursor: "pointer",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "0px 0px 0px 8px ", // Adjust the padding to decrease space
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: "#FFFFFF",
        padding: "0px 8px 0px 1px",
        visibility: state.isDisabled ? "hidden" : "visible",
        ":hover": {
            color: "#DDE8F8",
        },
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        padding: "0px",
        margin: "0px",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        visibility: "hidden",
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: "#FFFFFF",
    }),
};

export const timezoneStyle = {
    option: (provided: any, state: any) => ({
        ...provided,
        color: "#2A2A2A",
        backgroundColor: state.isSelected ? "#F3F3F3" : "#FFFFFF",
        ":hover": {
            backgroundColor: state.isSelected ? "#F3F3F3" : "#DDE8F8",
            color: "#2A2A2A",
        },
        cursor: "pointer",
    }),
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "#FFFFFF",
        width: "auto",
        right: 0,
    }),
    control: (provided: any, state: any) => ({
        ...provided,
        padding: "0px 0px",
        color: "#8f8f8f",
        backgroundColor: "#434343",
        border: "none",
        boxShadow: state.menuIsOpen ? "0 0 0 1px #FFFFFF" : "none", // Change this to your desired border color
        ":hover": {
            borderColor: state.menuIsOpen ? "#FFFFFF" : provided.borderColor,
        },
        width: "fit-content",
        height: "fit-content",
        cursor: "pointer",
    }),
    input: (provided: any) => ({
        ...provided,
        color: "#FFFFFF",
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        padding: "0px 0px 0px 8px ", // Adjust the padding to decrease space
    }),
    dropdownIndicator: (provided: any, state: any) => ({
        ...provided,
        color: "#FFFFFF",
        padding: "0px 8px 0px 1px",
        visibility: state.isDisabled ? "hidden" : "visible",
        ":hover": {
            color: "#DDE8F8",
        },
    }),
    indicatorsContainer: (provided: any) => ({
        ...provided,
        padding: "0px",
        margin: "0px",
    }),
    indicatorSeparator: (provided: any, state: any) => ({
        ...provided,
        visibility: "hidden",
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: "#cccccc",
    }),
}