import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Checkmark, SortingArrows } from "../common/ProjectIcons";
import { ProviderType } from "../common/ObjectTypes";
import NoResults from "../common/NoResults";
import Spinner from "../common/Spinner";

type StepOneProps = {
    providerTypes: ProviderType[],
    reloadProviderTypes: boolean,
    loading: boolean,
    onSelectProviderType: Function,
    onSearchProviderType: Function,
    onPageClickProviderTypes: Function,
    providerTypesTotal: number,
    providerTypesOffset: number,
    pageLoaded: boolean
}

const NewConnectionStepOne = ({
    providerTypes,
    reloadProviderTypes,
    loading,
    onSelectProviderType,
    onSearchProviderType,
    onPageClickProviderTypes,
    providerTypesTotal,
    providerTypesOffset,
    pageLoaded
}: StepOneProps) => {
    const { t } = useTranslation();
    const [subStr, setSubStr] = useState("");
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const tableContainerRef = useRef<HTMLDivElement | null>(null);

    const handleScroll = () => {
        if (!tableContainerRef.current || reloadProviderTypes) return;

        const { scrollTop, scrollHeight, clientHeight } = tableContainerRef.current;

        if (scrollTop + clientHeight >= scrollHeight - 10) { // User scrolled to bottom
            onPageClickProviderTypes(); // Load next page
        }
    };

    useEffect(() => {
        const container = tableContainerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }
        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [reloadProviderTypes, providerTypesTotal]);

    return (
        <div className="mt-8">
            {/* div with text and filters */}
            <div className="flex flex-col justify-between w-full mb-5">
                <label className="text-off-black text-lg">Select the data provider you wish to connect to.</label>
                <input
                    id="search"
                    placeholder={t("Search provider types") || ""}
                    value={subStr}
                    className="p-1.5 w-80 h-auto mt-4 border border-neutral-gray-200 rounded rounded-sm px-2"
                    onChange={(e) => {
                        onSearchProviderType(e)
                        setSubStr(e.target.value)
                    }}
                >
                </input>
            </div>
            <div className="flex flex-row mb-6">
                {reloadProviderTypes ?
                    <div className="w-2/3">
                        <Spinner />
                    </div>
                    :
                    <div className="w-2/3">
                        {providerTypes.length < 1 &&
                            <NoResults
                                headerMsg={t("No Provider Types Found")}
                                labelMsg={t("You may need to contact system admin.")}
                            />}
                        {providerTypes.length >= 1 &&
                            <div ref={tableContainerRef} className="w-full h-[450px] overflow-y-scroll border-y border-gray-lines">
                                <table className="w-full cursor-pointer border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                                    <thead className="sticky top-0 z-10 border border-gray-lines px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                        <tr>
                                            <th className="w-7"></th>
                                            <th className="p-3 w-3/4">
                                                {t("Provider")}
                                                <span><SortingArrows /></span>
                                            </th>
                                            <th className="p-3">
                                                {t("Help Center")}
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody className="border border-gray-lines ">
                                        {providerTypes.map((pt: ProviderType) => {
                                            return (
                                                <tr key={pt.id !== "" ? pt.id : Math.random()} className={`border-b border-gray-lines ${(selectedType === pt.id && pt.id !== "") ? "bg-light-blue " : "hover:bg-light-blue"}`}

                                                >
                                                    <td className={"p-3 w-7"}>{(selectedType === pt.id && pt.id !== "") ? <Checkmark /> : ""}</td>
                                                    <td className="p-3" onClick={() => {
                                                        onSelectProviderType(pt)
                                                        setSelectedType(pt.id)
                                                    }}>
                                                        {pt.name}
                                                    </td>
                                                    {pt.help_center_url ? (
                                                        <td className="p-3 hover:bg-light-blue">
                                                            <a href={pt.help_center_url} target="_blank" className="text-blue-basic">
                                                                {t("Learn More")}
                                                            </a>
                                                        </td>
                                                    ) : <td className="p-3 hover:bg-light-blue"></td>}
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                            </div>
                        }
                        {/* {providerTypesTotal > page_size && <Pagination
                            showText={false}
                            handlePageClick={onPageClickProviderTypes}
                            pageCount={Math.ceil(providerTypesTotal / page_size)}
                            resultStart={providerTypesOffset + 1}
                            resultEnd={(providerTypesOffset + page_size) < providerTypesTotal ? (providerTypesOffset + page_size) : providerTypesTotal}
                            totalCount={providerTypesTotal}
                            take={page_size}
                            loading={!pageLoaded}
                            mainclassname="p-6 pt-2 w-full flex flex-row justify-between bg-very-light-gray opacity-80"
                            initialPage={Math.floor(providerTypesOffset / page_size)}
                        />} */}
                        {loading && <div className="relative w-full h-1 bg-gray-200 overflow-hidden mt-2">
                            <div className="absolute top-0 left-0 w-1/3 h-full bg-dark-green animate-loading"></div>
                        </div>}
                    </div>}
                <div className="border border-gray-lines ml-4 py-4 px-5 w-1/3 h-fit bg-white">
                    <p className="text-lg text-medium-gray mb-4">{t("Can't find what you are looking for?")}</p>
                    <p>{t("We are constantly working on integrating with new technologies. Please reach out to info@projectgundi.org with any requests.")}</p>
                    <br /><p>{t("Learn more about Gundi in the Help Center or contact support for help.")}</p>
                    <div className="flex flex-row">
                        <button onClick={() => window.open("https://support.earthranger.com/connections/new-connection", "_blank")} className="mt-4 mr-3 bg-dark-green text-light-green font-bold px-4 py-2 rounded-sm">{t("Help Center")}</button>
                        <button onClick={() => window.open("https://support.earthranger.com/en_US/gundi-getting-help/gundi-contact-us", "_blank")} className="mt-4 bg-light-green text-dark-green font-bold px-4 py-2 rounded-sm">{t("Contact Support")}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NewConnectionStepOne