import React, { useState, ChangeEventHandler } from "react";
import GlobalSearch from "../common/GlobalSearch";
import { ArrowDown, ArrowUp, Clear, FilterIcon } from "../common/ProjectIcons";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SelectInput from "../common/SelectInput";
import { useTranslation } from "react-i18next";

type LogsFilterProps = {
    filters: any[],
    globalSearchStr?: string,
    timezones: any[],
    selectedTimezone: any,
    onChangeGlobalSearch: Function,
    onRemoveTag: any,
    onResetFilters: any,
    onChangeStatusFilter: ChangeEventHandler,
    onChangeLogType: ChangeEventHandler,
    onChangeLogDate: Function,
    onSelectTimezone: Function
}

const LogsFilter = ({
    filters,
    globalSearchStr,
    timezones,
    selectedTimezone,
    onChangeGlobalSearch,
    onRemoveTag,
    onResetFilters,
    onChangeStatusFilter,
    onChangeLogType,
    onChangeLogDate,
    onSelectTimezone
}: LogsFilterProps) => {
    const { t } = useTranslation();
    const [statusFilterExpanded, setStatusFilterExpanded] = useState(true);
    const [logTypeFilterExpanded, setLogTypeFilterExpanded] = useState(true);
    const [logDateTimeFilterExpanded, setLogDateTimeFilterExpanded] = useState(true);

    const toggleStatusFilter = () => setStatusFilterExpanded(!statusFilterExpanded);
    const toggleLogTypeFilter = () => setLogTypeFilterExpanded(!logTypeFilterExpanded);
    const toggleDateTimeFilter = () => setLogDateTimeFilterExpanded(!logDateTimeFilterExpanded);

    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);

    return (
        <div className="w-80 text-off-black bg-very-light-gray mr-3 mb-3 h-auto pb-3 px-3 divide-y divide-gray-lines max-h-screen overflow-y-scroll overflow-x-hidden">
            {/* global */}
            <GlobalSearch
                onChangeGlobalSearch={onChangeGlobalSearch}
                savedString={globalSearchStr}
            />
            <div className="flex flex-row justify-between mt-2 pt-2">
                <h3><FilterIcon /> {t("Filters")}</h3>
                {filters.length > 0 && <h3 className="bg-dark-green text-white text-sm py-1 px-3 rounded-full">{filters.length}</h3>}
            </div>
            {/* tags of all of the filters */}
            {filters.length > 0 &&
                <div className="flex flex-col mt-3 pt-3">
                    {filters.map((item: any) => {
                        return (
                            <p className="text-sm w-fit py-1 px-2 bg-light-green mt-1 rounded-sm items-end"
                                key={item.id}
                            >
                                {t(item.name)}
                                <span className="pb-3 text-dark-green cursor-pointer"
                                    onClick={() => onRemoveTag(item.id)}>
                                    <Clear />
                                </span>
                            </p>
                        )
                    })}
                    <button className="w-fit bg-very-light-gray text-dark-green mt-3"
                        onClick={onResetFilters}
                    >{t("Clear All")}</button>
                </div>}
            {/* log type */}
            <div className="my-6 pt-3">
                <div className="w-full flex flex-row justify-between" onClick={toggleLogTypeFilter}>
                    <label className="font-semibold">{t("Filter by Log Type")}</label>{logTypeFilterExpanded ? <ArrowUp /> : <ArrowDown />}
                </div>
                {logTypeFilterExpanded &&
                    <div>
                        <div className="mt-1">
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("cdc")}
                                type="checkbox"
                                value="cdc"
                                id="log_type"
                                onChange={onChangeLogType} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("Changes")}
                            </label>
                        </div>
                        <div>
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("ev")}
                                type="checkbox"
                                value="ev"
                                id="log_type"
                                onChange={onChangeLogType} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("Activity")}
                            </label>
                        </div>
                    </div>
                }
            </div>
            {/* status */}
            <div className="my-6 pt-3">
                <div className="w-full flex flex-row justify-between" onClick={toggleStatusFilter}>
                    <label className="font-semibold">{t("Filter by Log Level")}</label>{statusFilterExpanded ? <ArrowUp /> : <ArrowDown />}
                </div>
                {statusFilterExpanded &&
                    <div>
                        <div className="mt-1">
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("10")}
                                type="checkbox"
                                value="10"
                                id="log_level"
                                onChange={onChangeStatusFilter} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("All Logs")}
                            </label>
                        </div>
                        <div>
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("20")}
                                type="checkbox"
                                value="20"
                                id="log_level"
                                onChange={onChangeStatusFilter} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("Basic")}
                            </label>
                        </div>
                        <div>
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("30")}
                                type="checkbox"
                                value="30"
                                id="log_level"
                                onChange={onChangeStatusFilter} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("Warning")}
                            </label>
                        </div>
                        <div>
                            <input
                                className="accent-dark-green cursor-pointer"
                                checked={filters.map((a: any) => a.id).includes("40")}
                                type="checkbox"
                                value="40"
                                id="log_level"
                                onChange={onChangeStatusFilter} />
                            <label
                                className="inline-block mx-2 text-secondary-gray"
                            >
                                {t("Error")}
                            </label>
                        </div>
                    </div>
                }
            </div>
            {/* date & time */}
            <div className="my-6 pt-3">
                <div className="w-full flex flex-row justify-between" onClick={toggleDateTimeFilter}>
                    <label className="font-semibold">{t("Filter by Date & Time")}</label>{logDateTimeFilterExpanded ? <ArrowUp /> : <ArrowDown />}
                </div>
                {logDateTimeFilterExpanded &&
                    <div>
                        {/* <SelectInput
                            options={timezones}
                            data-testid="select_timezone_activity_logs_filter"
                            className={"basic-single text-black pb-1 w-60 text-sm"}
                            defaultValue={{
                                label: selectedTimezone.label,
                                value: selectedTimezone.value
                            }}
                            isDisabled={false}
                            isLoading={false}
                            isClearable={false}
                            isSearchable={true}
                            isMulti={false}
                            name={"Filter by Organization"}
                            onChange={(e: any) => onSelectTimezone(e)}
                        /> */}
                        <div className="flex flex-col mt-2">
                            <label
                                className="inline-block text-sm text-secondary-gray"
                            >
                                {t("Start")}
                            </label>
                            <DatePicker
                                selected={filters.map((a: any) => a.name).includes("Start Date & Time") ? new Date(filters.find((a: any) => a.name === "Start Date & Time").id) : null}
                                onChange={(date: Date) => {
                                    setStartDate(date)
                                    onChangeLogDate(date, "from_date")
                                }}
                                showTimeSelect
                                timeIntervals={15}
                                className="border border-gray-lines rounded-sm p-1 mt-1 text-secondary-gray text-sm w-60"
                                dateFormat="yyyy-MM-dd HH:mm"
                                placeholderText="Select start date"
                            />
                        </div>
                        <div className="flex flex-col mt-2">
                            <label
                                className="inline-block text-sm text-secondary-gray"
                            >
                                {t("End")}
                            </label>
                            <DatePicker
                                selected={filters.map((a: any) => a.name).includes("End Date & Time") ? new Date(filters.find((a: any) => a.name === "End Date & Time").id) : null}
                                minDate={startDate}
                                onChange={(date: Date) => {
                                    setEndDate(date)
                                    onChangeLogDate(date, "to_date")
                                }}
                                showTimeSelect
                                timeIntervals={15}
                                className="border border-gray-lines rounded-sm p-1 mt-1 text-secondary-gray text-sm w-60"
                                dateFormat="yyyy-MM-dd HH:mm"
                                placeholderText="Select end date"
                            />
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default LogsFilter;