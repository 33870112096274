import React, { useEffect, useState } from "react";
import { useKeycloakToken } from '../common/useKeycloak';
import { useNavigate, useParams } from 'react-router-dom';
import { Connection, NewIntegration, Organization, ProviderType, buildProviderType, buildNewIntegration, ConnectionConfiguration } from "../common/ObjectTypes";
import { createNewConnection } from "../../api/connections/connectionsApi";
import Spinner from "../common/Spinner";
import { filterDestinationOwner } from "../destinations/DestinationHelperFunctions";
import { toast } from "react-toastify";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import addKeywords from 'ajv-keywords';
import { loadOrganizations } from "../../api/organizations/loadOrganizations";
import { page_size } from "../../api/apiUtils";
import { useTranslation } from "react-i18next";
import NewConnectionStepTwo from "./NewConnectionStepTwo";
import { loadProviderTypes } from "../../api/connections/loadProviderTypesNewConnection";
import { useKeycloak } from "@react-keycloak/web";

export function ManageAddConnectionStepTwo() {
    type FilterTypes = {
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    };
    function createFilterTypes(
        endpoint__in: FilterTags[],
        enabled: FilterTags[],
        type__in: FilterTags[],
        owner__in: FilterTags[],
        ordering: string,
        search: string
    ): FilterTypes {
        return {
            endpoint__in: endpoint__in || [],
            enabled: enabled || [],
            type__in: type__in || [],
            owner__in: owner__in || [],
            ordering: ordering || "",
            search: search || ""
        }
    }
    type FilterTags = {
        name: string,
        id: string
    };
    const { t } = useTranslation();
    const { pt_value } = useParams();
    const [token, showLoginModal, setShowLoginModal] = useKeycloakToken();
    const { keycloak, initialized } = useKeycloak();
    const [destinationFilter, setDestinationFilter] = useState<FilterTypes>(createFilterTypes([], [], [], [], "", ""));
    const [initialConnection, setInitialConnection] = useState<NewIntegration>(buildNewIntegration(true))
    const [createdConnection, setCreatedConnection] = useState<Connection>()
    const [updatedConfigurations, setUpdatedConfigurations] = useState<any[]>([]);
    const [errors, setErrors] = useState({});
    const [rsjfErrors, setRsjfErrors] = useState<any[]>([]);
    const [routeId, setRouteId] = useState("");

    const [reloadDestinations, setReloadDestinations] = useState(false);

    const [providerTypes, setProviderTypes] = useState<ProviderType[]>([]);
    const [organizations, setOrganizations] = useState<Organization[]>([]);
    const [loadingOrganizations, setLoadingOrganizations] = useState(false);
    const [loadMoreOrganizations, setLoadMoreOrganizations] = useState(true);
    const [organizationOffset, setOrganizationOffset] = useState<number>(0);
    const [orgOffset, setOrgOffset] = useState<number>(0);

    const [selectedProviderType, setSelectedProviderType] = useState<ProviderType>(buildProviderType());
    const [pageLoaded, setPageLoaded] = useState(false);
    const [currentStep, setCurrentStep] = useState(2);
    const navigate = useNavigate();

    const getSaveButtonClassName = (step: number, disabled: boolean): string => {
        if (step === 1) {
            return disabled
                ? "bg-gray-lines text-white"
                : "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 2) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 3) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        return "";
    };

    const loadOrganizationsData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [orgs, total_orgs, error] = await loadOrganizations(token, offset, search_str, organizations);
            setLoadingOrganizations(true);
            if (!error) {
                setOrganizations(orgs);
                if (offset + page_size >= total_orgs) {
                    setLoadMoreOrganizations(false);
                }
                setLoadingOrganizations(false);
            }
        }
    }

    const loadProviderTypesData = async (offset: number, search_str: string) => {
        if (token && typeof token === 'string' && !keycloak.isTokenExpired()) {
            const [provider_types, total_provider_types, error] = await loadProviderTypes(token, offset, search_str, providerTypes);
            if (!error) {
                setSelectedProviderType(provider_types[0]);
                setInitialConnection((prevConnection: any) => ({
                    ...prevConnection,
                    type: provider_types[0].id
                }))
                setPageLoaded(true);
            }


        }
    }


    useEffect(() => {
        pt_value && loadProviderTypesData(0, pt_value);
        loadOrganizationsData(organizationOffset, "");

    }, [token, reloadDestinations, providerTypes])


    const handleChangeEndpoint = (e: any) => {
        setInitialConnection((prevConnection: any) => ({
            ...prevConnection,
            base_url: e.target.value
        }))
    }

    const handleChangeSchemaForm = (e: any, action_id: string) => {
        const newConfig = {
            "action": action_id,
            "data": e
        }

        if (e) {
            const index_to_find = updatedConfigurations.findIndex((a: any) => a.action === action_id)
            if (index_to_find === -1) {
                // if this is a new config id, push it to the array
                setUpdatedConfigurations((prevArray: any[]) => [...prevArray, newConfig])
            } else {
                // if it is an existing config id, replace the old config with the new one
                const tempArray = updatedConfigurations
                tempArray[index_to_find] = newConfig
                setUpdatedConfigurations(tempArray)
            }
        }
        setInitialConnection((prevConnection: any) => ({
            ...prevConnection,
            configurations: updatedConfigurations
        }))
    }

    const handleChangeName = (e: any) => {
        setInitialConnection((prevConnection: any) => ({
            ...prevConnection,
            name: e.target.value
        }))
    }

    const handleSelectOwner = (e: any) => {
        setInitialConnection((prevConnection: any) => ({
            ...prevConnection,
            owner: e.value
        }))
        setDestinationFilter(filterDestinationOwner({
            target: {
                "name": e.label,
                "value": e.value,
            }
        }, destinationFilter));
        setReloadDestinations(true);
    }

    function handleToggleProviderEnabled(e: any) {
        setInitialConnection((prevProvider: NewIntegration) => ({
            ...prevProvider,
            enabled: !initialConnection.enabled,
        }))
    }

    const formIsValid = () => {
        let form_errors: any = {};
        let rsjf_errors: any = {};
        if (initialConnection.name.length === 0) form_errors.name = "Name is required."
        if (initialConnection.name.length > 50) form_errors.name = "Name cannot exceed 50 characters.";
        if (initialConnection.base_url.length > 0) {
            try {
                new URL(initialConnection.base_url);
            } catch (err) {
                form_errors.base_url = "Enter a valid url including the scheme."
            }
        }
        if (initialConnection.owner === "") form_errors.owner = "Select an organization."

        setErrors(form_errors)
        setRsjfErrors([])
        updatedConfigurations.forEach((config: any, index: number) => {
            const schema_index = selectedProviderType.actions.findIndex((a: any) => a.id === config.action);
            const ajv = new Ajv({ allErrors: true, verbose: true, strictTypes: false });
            ajv.addKeyword({
                keyword: 'is_executable',
                validate: function () {
                    return true;
                },
                errors: false
            });
            addFormats(ajv);
            addKeywords(ajv);

            let schema = selectedProviderType.actions[schema_index].schema;
            const validate = ajv.compile(schema);
            const data = { ...config.data };

            if (schema.properties && typeof schema.properties === 'object') {
                Object.keys(schema.properties).forEach((key) => {
                    if (!data.hasOwnProperty(key)) {
                        data[key] = null;
                    } else {
                        if (data[key] === undefined) {
                            data[key] = null;
                        }
                    }
                });
            } else {
                console.error('schema.properties is not defined or is not an object');
            }
            let modifiedSchema = JSON.parse(JSON.stringify(schema));
            if (schema.if && schema.then && schema.else) {
                // Validate against 'if' condition
                const validateIf = ajv.compile(schema.if);

                if (validateIf(data)) {
                    if (schema.then && typeof schema.then === "object") {
                        modifiedSchema = {
                            ...schema,
                            properties: { ...schema.properties, ...schema.then.properties },
                            required: schema.then.required || [],
                        };
                    }
                } else {
                    if (schema.else && typeof schema.else === "object") {
                        modifiedSchema = {
                            ...schema,
                            properties: { ...schema.properties, ...schema.else.properties },
                            required: schema.else.required || [],
                        };
                    }
                }
                const validateFinal = ajv.compile(modifiedSchema);
                const isValid = validateFinal(data);

                if (!isValid) {
                    validateFinal.errors?.forEach((error: any) => {
                        console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    });
                }
            }

            Object.keys(modifiedSchema.properties).forEach((key) => {
                if (!data.hasOwnProperty(key)) {
                    data[key] = null;
                } else if (data[key] === undefined) {
                    data[key] = null;
                }
            });

            const validateUpdated = ajv.compile(modifiedSchema);
            const valid = validateUpdated(data);
            if (!valid) {
                validateUpdated.errors?.forEach((error: any) => {
                    console.error(`Error in field '${error.instancePath}': ${error.message}`);
                    const field = error.instancePath.slice(1) || error.params.missingProperty;
                    const isFieldRequired = modifiedSchema.required && modifiedSchema.required.includes(field);
                    const hasPattern = modifiedSchema.properties[field]?.pattern;

                    // Skip validation if field is not required and is empty
                    if (!isFieldRequired && (data[field] === null || data[field] === undefined)) {
                        return;
                    }

                    // Validate pattern if defined
                    if (hasPattern && !new RegExp(hasPattern).test(data[field])) {
                        rsjf_errors[schema_index] = rsjf_errors[schema_index] || {};
                        rsjf_errors[schema_index][field] = `Field '${field}' does not match the required pattern.`;
                    }

                    if (!rsjf_errors[selectedProviderType.actions[schema_index].id]) {
                        rsjf_errors[selectedProviderType.actions[schema_index].id] = {};
                    }
                    rsjf_errors[selectedProviderType.actions[schema_index].id][field] = `Error in field '${field}': ${error.message}`;
                });
            }
        });


        setRsjfErrors({ ...rsjf_errors });
        return Object.keys(form_errors).length === 0 && Object.keys(rsjf_errors).length === 0;
    }

    const handleCreateConnection = async (connectionToAdd: NewIntegration) => {
        if (!formIsValid()) return;
        if (token && typeof token === 'string' && !keycloak.isTokenExpired() && formIsValid()) {
            const temp = await createNewConnection(token, connectionToAdd);
            setCreatedConnection(temp)
            setRouteId(temp.default_route.id);
            toast.success(`${t("New connection made, ready to add destinations")}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            navigate(`/addconnection/configuredestinations/${temp.id}`)

        }
    }

    const handleClickNext = (newStep: number) => {
        if (newStep === 3) {
            if (!formIsValid()) {
                setCurrentStep(currentStep)
                return
            }
            else {
                handleCreateConnection(initialConnection);
                setCurrentStep(newStep)
            }
        }
    }

    function handleSearchOrgDropdown(e: any) {
        if (e.length > 1) loadOrganizationsData(orgOffset, e);
    }

    const handleLoadMoreOrganizations = () => {
        setOrganizationOffset(organizationOffset + page_size);
        loadOrganizationsData(organizationOffset + page_size, "");
    }

    const handleCancel = () => {
        navigate("/")
    }

    return !pageLoaded ?
        (
            <Spinner />
        ) : (
            <div>
                <div className="bg-white shadow static inset-x-0 px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <div className="flex flex-col">
                        {currentStep === 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("Create a Connection")}</h1>
                        </div>}
                        {currentStep > 1 && <div>
                            <h1 className="text-3xl font-semibold mb-3">{t("New")} {selectedProviderType ? selectedProviderType.name : ''} {t("Connection")}</h1>
                            {/* tabs for provider and destinations */}
                            <div className="w-full flex flex-row font-semibold text-medium-gray">
                                <label className={currentStep === 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Provider")}
                                </label>
                                <label className={currentStep > 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                    {t("Connect Destinations")}
                                </label>
                            </div>
                        </div>}
                    </div>

                </div>
                <div className="px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                    <NewConnectionStepTwo
                        initialConnection={initialConnection}
                        errors={errors}
                        rsjfErrors={rsjfErrors}
                        onChangeEndpoint={handleChangeEndpoint}
                        onChangeSchemaForm={handleChangeSchemaForm}
                        onChangeName={handleChangeName}
                        onSelectOwner={handleSelectOwner}
                        selectedProviderType={selectedProviderType}
                        organizations={organizations}
                        loadMoreOrgs={handleLoadMoreOrganizations}
                        orgInputChange={handleSearchOrgDropdown}
                        onChangeProviderEnabled={handleToggleProviderEnabled}
                    />
                </div>

                <div className="fixed flex flex-row bottom-0 right-0 pr-5 py-3 pl-16 bg-white/10 backdrop-blur w-full shadow-lg">
                    <button className={`px-8 py-2 font-bold rounded mr-3 ${getSaveButtonClassName(currentStep, false)}`}
                        onClick={() => handleClickNext(currentStep + 1)}
                    >
                        {currentStep === 3 ? t("Finish") : t("Next")}
                    </button>
                    <button className="text-secondary-gray font-bold rounded py-2 px-3 mx-2"
                        type="button"
                        onClick={handleCancel}
                    >
                        {t("Cancel")}
                    </button>
                </div>
            </div>
        )
}