import React, { useEffect } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { Tooltip } from '../Tooltip';
import './styles/CustomRangeStyles.css';

const CustomRangeWidget: React.FC<WidgetProps> = ({
    schema,
    value,
    label,
    required,
    onChange,
    options,
}) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value);
        // Update the CSS variable for the selected value
        e.target.style.setProperty('--value', `${(e.target.valueAsNumber - Number(e.target.min)) / (Number(e.target.max) - Number(e.target.min)) * 100}%`);
    };

    const min = typeof schema.minimum === 'number' ? schema.minimum : 0;
    const max = typeof schema.maximum === 'number' ? schema.maximum : 100;
    const step = typeof options.step === 'number' ? options.step : 1;

    return (
        <div className="flex flex-col">
            {/* <div className='flex flex-row'>
                <label className="flex items-baseline">
                    <span className="mr-1">{label}</span>
                    {required && <span className="mr-1"> * </span>}
                    {schema.description && <Tooltip position="right" description={schema.description} />}
                </label>
            </div> */}
            <div>
                <span className="mt-1 text-center">Selected: {value}</span>
                <div className="flex flex-row">
                    <span className='mr-2'>{min}</span>
                    <input
                        type="range"
                        value={value || 0}
                        min={min}
                        max={max}
                        step={step}
                        onChange={handleChange}
                        className="mt-2 w-full h-2 bg-medium-gray rounded-lg cursor-pointer range-thumb"
                        style={{ '--value': `${(value - min) / (max - min) * 100}%` } as React.CSSProperties}
                    />
                    <span className='ml-2'>{max}</span>

                </div>
            </div>

        </div>
    );
};

export default CustomRangeWidget;