import React, { MouseEventHandler, useState } from "react";
import { useTranslation } from "react-i18next";
import { ConnectionConfiguration, Destination, Organization } from "../common/ObjectTypes";
import SchemaForm from "../common/SchemaForm";
import SelectInput from "../common/SelectInput";
import { ArrowDown, ArrowRight, AttentionIcon } from "../common/ProjectIcons";
import { getButtonText, getResponseText } from "../common/TestActionFunctions";
import Spinner from "../common/Spinner";
import { checkSchema } from "../common/SchemaFormTemplates/checkSchema";
import { isHiddenUrlDestinationType } from "./isHiddenUrlDestinationType";

type DestinationDetailsModalConfigProps = {
    destination: Destination,
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    onChange: Function,
    onChangeSelectedDestinationConfig: Function,
    onTestAction: Function,
    testActionLoading: boolean,
    testActionResponse: any,
    testActionStatus: number,
    onChangeDestinationEnabled: Function,
    onChangeSelectedDestinationOwner: Function,
    onSave: MouseEventHandler,
    onToggleModal: MouseEventHandler,
    errors: any,
    rsjfErrors: any,
}

const DestinationDetailsModalConfig = ({
    destination,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    onChange,
    onChangeSelectedDestinationConfig,
    onTestAction,
    testActionLoading,
    testActionResponse,
    testActionStatus,
    onChangeDestinationEnabled,
    onChangeSelectedDestinationOwner,
    onSave,
    onToggleModal,
    errors,
    rsjfErrors,
}: DestinationDetailsModalConfigProps) => {
    const { t } = useTranslation();
    const [isResponseVisible, setResponseVisible] = useState(false);
    const [activeActionId, setActiveActionId] = useState(null);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const shouldHideUrlField = isHiddenUrlDestinationType(destination.type.name);

    return (
        <div className="h-full flex flex-col">
            <div className="flex flex-col mb-2">
                <label className="text-sm text-secondary-gray">{t("Name")} *</label>
                <input
                    id="name"
                    placeholder=""
                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                    value={destination.name ? destination.name : ""}
                    onChange={(e) => onChange(e)}>
                </input>
                {errors.name && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.name}</p></div>}
            </div>
            <div className="flex flex-col my-2">
                {/* owner */}
                <label className="text-sm text-secondary-gray">{t("Organization")} *</label>
                <SelectInput
                    options={organizations.map((org: Organization) => {
                        return {
                            label: org.name,
                            value: org.id
                        }
                    })}
                    className={"basic-single text-black pb-1"}
                    defaultValue={{
                        label: destination.owner.name,
                        value: destination.owner.id
                    }}
                    isDisabled={false}
                    isLoading={false}
                    isClearable={false}
                    isSearchable={true}
                    isMulti={false}
                    name={t("Organization")}
                    onChange={(e: any) => onChangeSelectedDestinationOwner(e)}
                    onMenuScrollToBottom={loadMoreOrgs}
                    handleInputChange={orgInputChange}
                />
                {errors.owner && <div className="text-orange-600 bg-orange-100 py-1 px-2 rounded"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.owner}</p></div>}
            </div>
            {/* url */}
            {!shouldHideUrlField && <div className="flex flex-col">
                <label className="text-sm text-secondary-gray">{t("URL")} *</label>
                <input
                    id="base_url"
                    placeholder=""
                    className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                    value={destination.base_url ? destination.base_url : ""}
                    onChange={(e) => onChange(e)}>
                </input>
                {errors.base_url && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.base_url}</p></div>}
            </div>}
            <div className="flex-grow pt-14">
                {/* <label>Configurations</label> */}
                {destination.type.actions.filter((item: any) => item.type !== "pull").map((item: any) => {
                    const uiSchema = item.ui_schema ? { ...item.ui_schema } : {};
                    const formTitle = item.ui_schema && item.ui_schema['ui:title'] ? item.ui_schema['ui:title'] : item.name;
                    return (
                        <div key={item.id} className={checkSchema(item) ? "pb-14" : "hidden"}>
                            {checkSchema(item) && <SchemaForm
                                formTitle={formTitle}
                                schema={item.schema}
                                ui_schema={uiSchema}
                                formdata={destination.configurations.findIndex((a: ConnectionConfiguration) => a.action.id === item.id) !== -1 ? destination.configurations.find((a: ConnectionConfiguration) => a.action.id === item.id).data : {}}
                                onChange={(e: any) => onChangeSelectedDestinationConfig(e, item.id)}
                                isEditable={true}
                                errors={rsjfErrors[item.id]}
                            />}
                            {item.schema.hasOwnProperty('is_executable') && item.schema.is_executable === true &&
                                <div className="flex flex-col">
                                    <div className="flex flex-row items-center">
                                        <button className="opacity-100 bg-light-gray hover:bg-gray-lines text-dark-gray font-semibold rounded py-2 px-4 ml-1 mr-3 shadow"
                                            onClick={() => {
                                                onTestAction(item.id, item.value)
                                                setActiveActionId(item.id);
                                            }}>
                                            {getButtonText(item.value, t)}
                                        </button>
                                        {testActionLoading && activeActionId === item.id && <Spinner />}
                                        {!testActionLoading && testActionResponse !== null && activeActionId === item.id && <p className={getResponseText(item.value, testActionResponse, t)[1] !== null ? (getResponseText(item.value, testActionResponse, t)[1] ? "bg-light-green p-2 rounded-sm text-dark-green" : "bg-very-light-orange p-2 rounded-sm text-dark-gray") : "bg-light-gray p-2 rounded-sm text-dark-gray"}>
                                            {getResponseText(item.value, testActionResponse, t)[0]}
                                        </p>}
                                    </div>
                                    {!testActionLoading && testActionResponse !== null && activeActionId === item.id &&
                                        <div className="flex flex-col items-start">
                                            <button className="ml-1" onClick={() => setResponseVisible(!isResponseVisible)}>
                                                {t("View Details")} {isResponseVisible ? <ArrowDown /> : <ArrowRight />}
                                            </button>
                                            {isResponseVisible &&
                                                <pre className={getResponseText(item.value, testActionResponse, t)[1] !== null ? (getResponseText(item.value, testActionResponse, t)[1] ? "bg-light-green p-2 rounded-sm text-dark-green text-xs" : "bg-very-light-orange p-2 rounded-sm text-dark-gray") : "bg-light-gray p-2 rounded-sm text-dark-gray"}>
                                                    {JSON.stringify(testActionResponse, null, 2)}
                                                </pre>
                                            }
                                        </div>
                                    }
                                </div>}
                        </div>
                    )
                })}
            </div>

            {/* checkbox */}
            <div className="flex flex-row items-start mb-6">
                <input
                    className="mt-1.5 accent-dark-green cursor-pointer"
                    type="checkbox"
                    value=""
                    id="checkboxDefault"
                    checked={destination.enabled}
                    onChange={(e) => onChangeDestinationEnabled(e)} />
                <div className="flex flex-col">
                    <label
                        className="inline-block mx-2"
                    >
                        {t("Activate Destination")}
                    </label>
                    <label
                        className="inline-block mx-2 text-sm text-secondary-gray"
                    >
                        {t("This connection will send data to its destination.")}
                    </label>
                </div>
            </div>
            {/* advanced configurations */}
            {shouldHideUrlField && <div className="pb-14">
                <div className="mt-6 mb text-lg font-semibold text-secondary-gray" onClick={() => setShowAdvanced(!showAdvanced)}>
                    {showAdvanced ? <ArrowDown /> : <ArrowRight />} {t("Advanced Configurations")}
                </div>
                {showAdvanced &&
                    <div className="ml-8 flex flex-col">
                        <label className="text-sm text-secondary-gray">{t("URL")}</label>
                        <input
                            id="base_url"
                            placeholder=""
                            className="mt-2 p-1.5 border border-neutral-gray-200 rounded rounded-sm px-2"
                            value={destination.base_url ? destination.base_url : ""}
                            onChange={(e) => onChange(e)}>
                        </input>
                        {errors.base_url && <div className="text-orange-600 py-1 rounded flex flex-row items-center"><AttentionIcon /><p className="ml-1 mt-0.5">{errors.base_url}</p></div>}
                    </div>}
            </div>}
            {/* submit and cancel button */}
            <div className="sticky bottom-0 flex flex-row mt-2 bg-white/50 backdrop-blur-sm py-3">
                <button className="font-bold bg-dark-green hover:bg-status-green text-white font-bold rounded px-7 py-2"
                    type="submit"
                    onClick={onSave}>
                    {t("Save")}
                </button>
                <button className="font-bold text-medium-gray rounded px-7 mx-2"
                    type="button"
                    onClick={onToggleModal}>
                    {t("Cancel")}
                </button>
            </div>
        </div>

    )
}

export default DestinationDetailsModalConfig;