import moment from "moment-timezone";

const getFullTimezoneName = (timezone: string) => {
    try {
        return new Intl.DateTimeFormat("en", {
            timeZone: timezone,
            timeZoneName: "long",
        })
            .formatToParts()
            .find((part) => part.type === "timeZoneName")?.value || timezone;
    } catch {
        return timezone; // Fallback if the timezone is invalid
    }
};

export const getUtcOffsets = () => {
    const filteredTimezones = moment.tz.names().filter(
        (tz) => tz.includes("/") && !tz.startsWith("Etc/")
    );

    const utcOffsets = filteredTimezones.map((timezone) => {
        const offset = moment.tz(timezone).utcOffset(); // Offset in minutes
        const hours = Math.floor(Math.abs(offset) / 60);
        const minutes = Math.abs(offset) % 60;
        const sign = offset >= 0 ? "+" : "-";
        const numericOffset = `${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;
        const formattedOffset = `UTC${sign}${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}`;

        // Extract timezone abbreviation (e.g., CAT, PST, IST)
        const abbreviation = moment.tz(timezone).format("z");

        // Extract a more user-friendly name (e.g., "Central Africa Time")
        const fullName = getFullTimezoneName(timezone);

        return {
            value: numericOffset,
            timezone: timezone,
            label: `(${formattedOffset}) ${timezone}`, // "(UTC+02:00) Africa/Gaborone"
            subtitle: `${fullName} (${abbreviation})`, // "Central Africa Time (CAT)"
            searchTerms: [
                formattedOffset,
                fullName.toLowerCase(),
                abbreviation.toLowerCase(),
                timezone.toLowerCase()
            ],
        };
    });

    // Add Universal Time option manually for better searchability
    utcOffsets.push({
        value: "+00:00",
        timezone: "UTC",
        label: "(UTC+00:00) UTC",
        subtitle: "Coordinated Universal Time (UTC)",
        searchTerms: ["coordinated universal time", "utc", "coordinated"],
    });

    // Sort by UTC offset (ascending order)
    const sorted = utcOffsets.sort((a, b) => {
        const offsetA = moment.tz(a.timezone).utcOffset();
        const offsetB = moment.tz(b.timezone).utcOffset();
        return offsetA - offsetB;
    });

    return sorted;
};
