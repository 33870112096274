import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect, useState } from 'react';
import gundi_logo from '../../images/gundi_logo.png';
import { acceptEula } from '../../api/users/usersApi';
import { AttentionIcon } from './ProjectIcons';
import { useTranslation } from 'react-i18next';

export function EulaModal() {
  const { keycloak } = useKeycloak();
  const [eulaChecked, setEulaChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const { t } = useTranslation();
  const handleAccept = (e: any) => {
    setEulaChecked(e.target.checked);
    if (e.target.checked) setErrorMsg(false);
  };

  const handleContinue = async () => {
    if (eulaChecked) {
      if (keycloak.token)
        await acceptEula(keycloak.token);
      window.location.reload();
    } else setErrorMsg(true);
  }

  return (
    <div className={`fixed z-10 inset-0 overflow-y-auto bg-medium-gray bg-opacity-80`}>
      <div className="opacity-100 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
          <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <div className="z-30 opacity-100 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all my-24">
          <div className="text-left bg-white px-6 pt-5 ">
            <div className='flex flex-row justify-center items-baseline'>
              <img src={gundi_logo} alt="gundi logo" className="h-6" />
              <label className='ml-2 font-bold'>GUNDI</label>
            </div>
            <h3 className="text-lg leading-6 font-medium text-dark-gray">
              {t("You must accept Gundi's policies to continue")}
            </h3>
            <div className="mt-2">
              <p className="text-dark-gray text-md w-96">
                {t("By clicking accept policies, I agree to the")} <a href="https://projectgundi.org/User-Agreement" target="_blank" rel="noopener noreferrer" className='text-bright-blue'>{t("End User License Agreement")}</a> {`${t("and")} ${t("The (masculine)(plural)").toLowerCase()}`} <a href="https://projectgundi.org/Legal-Pages/Terms-of-Use" target="_blank" rel="noopener noreferrer" className='text-bright-blue'>{t("Site Terms & Conditions")}</a>
                , {t("and consent to the privacy policies referenced therein.")}
              </p>
            </div>
            <div className='my-4'>
              <input
                className="accent-dark-green cursor-pointer"
                checked={eulaChecked}
                type="checkbox"
                value="30"
                id="log_level"
                onChange={(e: any) => handleAccept(e)} />
              <label
                className="inline-block mx-2 text-dark-gray text-md"
              >
                {t("Accept Policies")}
              </label>
            </div>
            {errorMsg && <div className='bg-very-light-orange p-3 flex flex-row items-end rounded-sm text-sm mb-3'>
              <AttentionIcon /><p className='-mb-0.5 ml-2 text-dark-gray'>{t("You must check Accept Policies to continue")}</p>
            </div>}
          </div>
          <div className="bg-gray-50 px-4 pb-6 sm:px-6 flex justify-center">
            <button type="button" className="font-bold w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-6 py-3 bg-dark-green text-base text-white hover:bg-status-green focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" onClick={handleContinue}>
              {t("Continue")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}