import React, { ChangeEventHandler, MouseEventHandler, useState } from "react";
import { Destination, DestinationType, Organization, ProviderType, RoutingRule, Source, SourceWithConnections } from "../common/ObjectTypes";
import { NextArrow, PrevArrow, SortedDown, SortedUp, SortingArrows } from "../common/ProjectIcons";
import SourceFilter from "./SourcesFilter";
import NoResults from "../common/NoResults";
import Pagination from "../common/Pagination";
import Spinner from "../common/Spinner";
import { useNavigate } from 'react-router-dom';
import { page_size } from "../../api/apiUtils";
import { useTranslation } from "react-i18next";
import { useTimezone } from "../../context/TimezoneContext";
import { formatSourceDatetime } from "./SourceHelperFunctions";

type SourcesListProps = {
    sources: SourceWithConnections[],
    totalCount: number,
    take: number,
    offset: number,
    onPageClicked: MouseEventHandler,
    loading: boolean,
    organizations: Organization[],
    destinationTypes: DestinationType[],
    destinationUrls: string[],
    providerTypes: ProviderType[],
    filters: any[],
    globalSearchStr?: string,
    onChangeGlobalSearch: Function,
    onChangeOwner: ChangeEventHandler,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeDestinationType: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onChangeEndpoint: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    loadingDestinationUrls: boolean,
    loadMoreDestinationUrls: boolean,
    onLoadMoreDestinationUrls: Function,
    onChangeProviderTypeSearchBar: ChangeEventHandler,
    onChangeProviderType: ChangeEventHandler,
    loadingProviderTypes: boolean,
    loadMoreProviderTypes: boolean,
    onLoadMoreProviderTypes: Function,
    onRemoveTag: any,
    onResetFilters: any,
    requestSort: Function,
    onViewDetails: Function,
}

const SourcesList = ({
    sources,
    totalCount,
    take,
    offset,
    onPageClicked,
    loading,
    organizations,
    destinationTypes,
    destinationUrls,
    providerTypes,
    filters,
    globalSearchStr,
    onViewDetails,
    onChangeGlobalSearch,
    onChangeOwner,
    onChangeOwnerSearchBar,
    loadingOrganizations,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeDestinationType,
    onLoadMoreDestinationTypes,
    onChangeDestinationTypeSearchBar,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onChangeEndpoint,
    onChangeDestinationUrlSearchBar,
    loadingDestinationUrls,
    loadMoreDestinationUrls,
    onLoadMoreDestinationUrls,
    onChangeProviderTypeSearchBar,
    onChangeProviderType,
    loadingProviderTypes,
    loadMoreProviderTypes,
    onLoadMoreProviderTypes,
    onResetFilters,
    onRemoveTag,
    requestSort,
}: SourcesListProps) => {
    const { t } = useTranslation();
    const { timezone } = useTimezone();
    const [sortDirection, setSortDirection] = useState(true);
    const [sortByColumn, setSortByColumn] = useState("external_id");
    const [sortByDateCreated, setSortByDateCreated] = useState(false);

    return (
        <div className="bg-very-light-gray">
            <div className="mb-3 flex justify-between">
                <div>
                    <h1 data-testid="sources_page_header" className="text-3xl font-bold mb-3">
                        {t("Sources")}
                    </h1>
                </div>
            </div>
            <div className="flex flex-row items-start w-full">
                {/* FILTERS */}
                <SourceFilter
                    filters={filters}
                    globalSearchStr={globalSearchStr}
                    organizations={organizations}
                    destinationTypes={destinationTypes}
                    destinationUrls={destinationUrls}
                    providerTypes={providerTypes}
                    onChangeOwner={onChangeOwner}
                    loadingOrganizations={loadingOrganizations}
                    loadMoreOrganizations={loadMoreOrganizations}
                    onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                    onLoadMoreOrganizations={onLoadMoreOrganizations}
                    loadingProviderTypes={loadingProviderTypes}
                    loadMoreProviderTypes={loadMoreProviderTypes}
                    onLoadMoreProviderTypes={onLoadMoreProviderTypes}
                    onChangeDestinationType={onChangeDestinationType}
                    onChangeProviderType={onChangeProviderType}
                    loadingDestinationTypes={loadingDestinationTypes}
                    onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                    onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                    loadingDestinationUrls={loadingDestinationUrls}
                    loadMoreDestinationUrls={loadMoreDestinationUrls}
                    onLoadMoreDestinationUrls={onLoadMoreDestinationUrls}
                    onChangeProviderTypeSearchBar={onChangeProviderTypeSearchBar}
                    onChangeEndpoint={onChangeEndpoint}
                    onChangeGlobalSearch={onChangeGlobalSearch}
                    onRemoveTag={onRemoveTag}
                    onResetFilters={onResetFilters}
                    showOrgFilter={true}
                    showProviderTypeFilter={true}
                    showDestinationTypeFilter={true}
                    onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                    loadMoreDestinationTypes={loadMoreDestinationTypes}
                />
                <div className="flex flex-col w-full">


                    {sources.length < 1 &&
                        <NoResults
                            headerMsg={t("No Results Found")}
                            labelMsg={`${t("You may need to adjust your search or filters to find what you are looking for")}.`}
                        />}
                    {sources.length >= 1 &&
                        <table className="table-auto h-auto w-full border border-gray-lines text-off-black rounded-md bg-very-light-gray">
                            <thead className="px-3 pt-3 pb-2 font-bold uppercase text-sm bg-light-gray text-left">
                                <tr>
                                    <th className="p-3">
                                        {t("External ID")}
                                        <span onClick={() => {
                                            requestSort("external_id", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("external_id");
                                            setSortByDateCreated(false);
                                        }}>
                                            {sortByColumn === "external_id" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Provider")}
                                        <span onClick={() => {
                                            requestSort("integration__id", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByColumn("integration__id");
                                            setSortByDateCreated(false);
                                        }}>
                                            {sortByColumn === "integration__id" ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                    <th className="p-3">
                                        {t("Destinations")}
                                    </th>
                                    {/* <th className="p-3">
                                        Connections
                                    </th> */}
                                    <th className="p-3">
                                        {t("Created At")}
                                        <span onClick={() => {
                                            requestSort("created_at", sortDirection)
                                            setSortDirection(!sortDirection)
                                            setSortByDateCreated(true);
                                        }}>
                                            {sortByDateCreated ?
                                                sortDirection ? <SortedUp /> : <SortedDown />
                                                : <SortingArrows />}
                                        </span>
                                    </th>
                                </tr>
                            </thead>
                            {loading ?
                                <tbody>
                                    <tr>
                                        <td colSpan={7} className="p-3 text-center">
                                            <Spinner />
                                        </td>
                                    </tr>
                                </tbody>
                                : <tbody className="text-sm">
                                    {sources.map((src: any) => {
                                        return (
                                            <tr key={src.source.id}
                                                className="border border-gray-lines bg-white">
                                                <td className="p-3">
                                                    {src.source.external_id}
                                                </td>
                                                <td className="p-3">
                                                    {src.source.provider.name}
                                                </td>
                                                <td className="p-3 text-blue-basic cursor-pointer">
                                                    {src.source.destinations.map((dest: Destination) => {
                                                        let dest_url: string | URL;
                                                        try {
                                                            dest_url = new URL(dest.base_url).hostname
                                                        } catch (err) {
                                                            dest_url = ""
                                                        }
                                                        return (<p className="hover:underline" key={dest.id} onClick={() => onViewDetails(dest.id)}>{dest_url}</p>)
                                                    })}
                                                </td>
                                                {/* <td className="p-3 text-blue-basic cursor-pointer">
                                                    {src.connections.map((conn: any) => {
                                                        return <p className="hover:underline" key={conn.id} onClick={() => navigate(`/connections/${conn.id}/overview`)}>{conn.provider.name}</p>
                                                    })}
                                                </td> */}
                                                <td className="p-3">
                                                    {formatSourceDatetime(src.source.created_at, timezone)}
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>}
                        </table>}
                    {totalCount > page_size && <Pagination
                        showText={true}
                        handlePageClick={onPageClicked}
                        pageCount={Math.ceil(totalCount / take)}
                        resultStart={offset + 1}
                        resultEnd={(offset + take) < totalCount ? (offset + take) : totalCount}
                        initialPage={Math.floor(offset / take)}
                        totalCount={totalCount}
                        take={take}
                        loading={loading}
                        mainclassname="w-full mt-2 flex flex-row justify-between bg-very-light-gray opacity-80"
                    />}
                </div>
            </div>
        </div>
    )
};

export default SourcesList;