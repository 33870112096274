import React, { ChangeEventHandler, MouseEventHandler } from "react";
import NewConnectionStepOne from "./NewConnectionStepOne";
import { ProviderType, Organization, Destination, NewIntegration, DestinationType } from "../common/ObjectTypes";
import NewConnectionStepTwo from "./NewConnectionStepTwo";
import NewConnectionStepThree from "./NewConnectionStepThree";
import { useTranslation } from "react-i18next";

type AddConnectionDetailsProps = {
    initialConnection: NewIntegration,
    errors: any,
    rsjfErrors: any,
    providerTypes: ProviderType[],
    reloadProviderTypes: boolean,
    destinationTypes: DestinationType[],
    organizations: Organization[],
    loadMoreOrgs: Function,
    orgInputChange: Function,
    destinations: Destination[],
    existingDestPhase0: Destination[],
    existingDestPhase1: Destination[],
    existingDestPhase2: Destination[],
    existingDestPhase3: Destination[],
    onSelectExistingDestination: Function,
    onChangeOwnerSearchBar: ChangeEventHandler,
    loadingOrganizations: boolean,
    loadMoreOrganizations: boolean,
    onLoadMoreOrganizations: Function,
    onChangeStateFilter: ChangeEventHandler,
    onChangeTypeFilter: ChangeEventHandler,
    onChangeOwnerFilter: ChangeEventHandler,
    onChangeDestinationUrlSearchBar: ChangeEventHandler,
    onChangeDestinationTypeSearchBar: ChangeEventHandler,
    onLoadMoreDestinationTypes: Function,
    loadingDestinationTypes: boolean,
    loadMoreDestinationTypes: boolean,
    onGlobalDestinationFilter: Function,
    onRemoveDestinationFilterTag: any,
    onResetDestinationFilters: any,
    filters: any[],
    onChangeEndpoint: Function,
    onChangeSchemaForm: Function,
    onChangeName: Function,
    onSelectOwner: Function,
    onSelectProviderType: Function,
    onSearchProviderType: Function,
    onPageClickProviderTypes: MouseEventHandler,
    providerTypesOffset: number,
    providerTypesTotal: number,
    selectedProviderType: ProviderType,
    isProviderTypeSelected: boolean,
    onClickNext: Function,
    currentStep: number,
    onCancel: MouseEventHandler,
    pageLoaded: boolean,
    onClickAddDestination: Function,
    addExistingDest: boolean,
    updatingDestinations: boolean,
    onAddExistingDestinations: MouseEventHandler,
    onViewDetails: Function,
    onChangeProviderEnabled: Function,
}

const AddConnectionDetails = ({
    initialConnection,
    errors,
    rsjfErrors,
    providerTypes,
    reloadProviderTypes,
    destinationTypes,
    organizations,
    loadMoreOrgs,
    orgInputChange,
    destinations,
    existingDestPhase0,
    existingDestPhase1,
    existingDestPhase2,
    existingDestPhase3,
    onSelectExistingDestination,
    filters,
    onChangeOwnerSearchBar,
    loadingOrganizations,
    loadMoreOrganizations,
    onLoadMoreOrganizations,
    onChangeStateFilter,
    onChangeTypeFilter,
    onChangeOwnerFilter,
    onChangeDestinationUrlSearchBar,
    onChangeDestinationTypeSearchBar,
    onLoadMoreDestinationTypes,
    loadingDestinationTypes,
    loadMoreDestinationTypes,
    onGlobalDestinationFilter,
    onRemoveDestinationFilterTag,
    onResetDestinationFilters,
    onChangeEndpoint,
    onChangeSchemaForm,
    onChangeName,
    onSelectOwner,
    onSelectProviderType,
    onSearchProviderType,
    onPageClickProviderTypes,
    providerTypesOffset,
    providerTypesTotal,
    selectedProviderType,
    isProviderTypeSelected,
    onClickNext,
    currentStep,
    onCancel,
    pageLoaded,
    onViewDetails,
    onClickAddDestination,
    addExistingDest,
    updatingDestinations,
    onAddExistingDestinations,
    onChangeProviderEnabled
}: AddConnectionDetailsProps) => {
    const { t } = useTranslation();
    const getSaveButtonClassName = (step: number, disabled: boolean): string => {
        if (step === 1) {
            return disabled
                ? "bg-gray-lines text-white"
                : "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 2) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        if (step === 3) {
            return "bg-dark-green hover:bg-status-green text-white";
        }
        return "";
    };

    return (
        <div className="pb-20 relative">
            <div className="bg-white shadow static inset-x-0 px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                <div className="flex flex-col">
                    {currentStep === 1 && <div>
                        <h1 className="text-3xl font-semibold mb-3">{t("Create a Connection")}</h1>
                    </div>}
                    {currentStep > 1 && <div>
                        <h1 className="text-3xl font-semibold mb-3">{t("New")} {selectedProviderType.name} {t("Connection")}</h1>
                        {/* tabs for provider and destinations */}
                        <div className="w-full flex flex-row font-semibold text-medium-gray">
                            <label className={currentStep === 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                {t("Connect Provider")}
                            </label>
                            <label className={currentStep > 2 ? "border-2 border-white border-b-status-green text-dark-gray mr-3 py-3" : "border-2 border-white mr-3 py-3"}>
                                {t("Connect Destinations")}
                            </label>
                        </div>
                    </div>}
                </div>

            </div>
            {/* This is the step by step */}
            <div className="px-12 pt-5 -mt-5 -mx-5 xl:-mx-12">
                {currentStep === 1 &&
                    <NewConnectionStepOne
                        providerTypes={providerTypes}
                        loading={false}
                        reloadProviderTypes={reloadProviderTypes}
                        onSelectProviderType={onSelectProviderType}
                        onSearchProviderType={onSearchProviderType}
                        onPageClickProviderTypes={onPageClickProviderTypes}
                        providerTypesTotal={providerTypesTotal}
                        providerTypesOffset={providerTypesOffset}
                        pageLoaded={pageLoaded}
                    />}
                {currentStep === 2 &&
                    <NewConnectionStepTwo
                        initialConnection={initialConnection}
                        errors={errors}
                        rsjfErrors={rsjfErrors}
                        onChangeEndpoint={onChangeEndpoint}
                        onChangeSchemaForm={onChangeSchemaForm}
                        onChangeName={onChangeName}
                        onSelectOwner={onSelectOwner}
                        selectedProviderType={selectedProviderType}
                        organizations={organizations}
                        loadMoreOrgs={loadMoreOrgs}
                        orgInputChange={orgInputChange}
                        onChangeProviderEnabled={onChangeProviderEnabled}
                    />}
                {currentStep === 3 &&
                    <NewConnectionStepThree
                        destinations={destinations}
                        existingDestPhase0={existingDestPhase0}
                        existingDestPhase1={existingDestPhase1}
                        existingDestPhase2={existingDestPhase2}
                        existingDestPhase3={existingDestPhase3}
                        onSelectExistingDestination={onSelectExistingDestination}
                        destinationTypes={destinationTypes}
                        organizations={organizations}
                        filters={filters}
                        onChangeStateFilter={onChangeStateFilter}
                        onChangeTypeFilter={onChangeTypeFilter}
                        onChangeDestinationTypeSearchBar={onChangeDestinationTypeSearchBar}
                        loadingDestinationTypes={loadingDestinationTypes}
                        loadMoreDestinationTypes={loadMoreDestinationTypes}
                        onLoadMoreDestinationTypes={onLoadMoreDestinationTypes}
                        onChangeOwnerFilter={onChangeOwnerFilter}
                        onChangeOwnerSearchBar={onChangeOwnerSearchBar}
                        onLoadMoreOrganizations={onLoadMoreOrganizations}
                        loadingOrganizations={loadingOrganizations}
                        loadMoreOrganizations={loadMoreOrganizations}
                        onChangeDestinationUrlSearchBar={onChangeDestinationUrlSearchBar}
                        onGlobalDestinationFilter={onGlobalDestinationFilter}
                        onRemoveDestinationFilterTag={onRemoveDestinationFilterTag}
                        onResetDestinationFilters={onResetDestinationFilters}
                        pageLoaded={pageLoaded}
                        addExistingDest={addExistingDest}
                        updatingDestinations={updatingDestinations}
                        onClickAddDestination={onClickAddDestination}
                        onAddExistingDestinations={onAddExistingDestinations}
                        onViewDetails={onViewDetails}
                    />}
            </div>
            <div className="fixed flex flex-row bottom-0 right-0 pr-5 py-3 pl-16 bg-white/10 backdrop-blur w-full shadow-lg">
                <button className={`px-8 py-2 font-bold rounded mr-3 ${getSaveButtonClassName(currentStep, !isProviderTypeSelected)}`}
                    onClick={() => onClickNext(currentStep + 1)}
                    disabled={!isProviderTypeSelected}
                >
                    {currentStep === 3 ? t("Finish") : t("Next")}
                </button>
                <button className="text-secondary-gray font-bold rounded py-2 px-3 mx-2"
                    type="button"
                    onClick={onCancel}
                >
                    {t("Cancel")}
                </button>
            </div>
        </div>
    )
}

export default AddConnectionDetails;