type FilterTags = {
    name: string,
    id: string
};

export const changeSourceGlobalSearch = (searchSubString: string, sourceFilter: any) => {
    sourceFilter.search = searchSubString;
    return sourceFilter;
}

export const filterSourceUrl = (e: any, sourceFilter: any) => {
    let tempArray: FilterTags[] = sourceFilter.destination_url__in
    if (tempArray.map(a => a.id).includes(e.target.value)) {
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e.target.value) removed.push(tempArray[item])
        }
        sourceFilter[e.target.id] = removed
    } else {
        let newTag = {
            name: e.target.name,
            id: e.target.value
        }
        tempArray.push(newTag)
        sourceFilter[e.target.id] = tempArray
    }
    return sourceFilter;
}

export const removeSourceFilterTag = (e: any, sourceFilter: any) => {
    if ((sourceFilter.destination_url__in.map((a: any) => a.id).includes(e))) {
        let tempArray: FilterTags[] = sourceFilter.destination_url__in
        let removed: FilterTags[] = []
        for (var item in tempArray) {
            if (tempArray[item].id !== e) removed.push(tempArray[item])
        }
        sourceFilter.destination_url__in = removed
    }
    return sourceFilter;
}

export const resetSourceFilters = () => {
    return {
        destination_url__in: [],
        enabled: [],
        type__in: [],
        owner__in: [],
        ordering: "",
        search: ""
    }
}

export const formatSourceDatetime = (datetime: string, timezone: any) => {
    const date = new Date(datetime);
    const sign = timezone.value.startsWith("-") ? -1 : 1;
    const hours = parseInt(timezone.value.slice(0, 3), 10);
    const minutes = parseInt(timezone.value.slice(-2), 10) / 60;
    const utcOffset = sign * (Math.abs(hours) + minutes);
    const offsetMilliseconds = utcOffset * 3600000;
    const adjustedDate = new Date(date.getTime() + offsetMilliseconds);

    return adjustedDate.toLocaleString("en-US", {
        weekday: "short",
        year: "numeric",
        month: "short",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    });
};