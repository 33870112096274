import React, { FormEventHandler, MouseEventHandler } from "react";
import { useTranslation } from "react-i18next";
import SelectInput from "../common/SelectInput";
import SchemaForm from "../common/SchemaForm";
import { NewIntegration, DestinationType, Organization } from "../common/ObjectTypes";

type GundiV1ModalProps = {
    onToggleModal: MouseEventHandler,
}

const GundiV1Modal = ({
    onToggleModal,
}: GundiV1ModalProps) => {
    const { t } = useTranslation();
    return (
        <div className="absolute left-0 z-30">
            <div className="fixed top-0 left-0 right-0 h-screen bg-dark-gray opacity-80"
                onClick={onToggleModal}></div>
            <div className="relative flex justify-center w-screen h-screen opacity-100">
                <div className="z-20 flex flex-col bg-very-light-gray w-1/3 h-fit overflow-y-scroll shadow rounded-lg">
                    <div className="flex flex-row-reverse">
                        <button
                            type="button"
                            onClick={onToggleModal}
                            className="text-medium-gray bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                            data-modal-hide="authentication-modal"
                        >
                            <svg aria-hidden="true" className="w-7 h-7 text-off-black" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                            <span className="sr-only">{t("Close Modal")}</span>
                        </button>
                    </div>
                    <div className="px-6 pb-6 lg:px-8 h-full">
                        <h2 className="text-xl mb-[8px] text-off-black text-[24px]">{t("Assistance needed for this Connection")}</h2>
                        <p className="text-secondary-gray text-md mb-[32px] text-[16px]">{t("Integrations with this technology are supported but cannot be configured directly in this portal. For assistance, please visit the Help Center for guidance or contact Support.")}</p>
                        <div className="flex flex-row mb-2">
                            <button onClick={() => window.open("https://support.earthranger.com/connections/new-connection", "_blank")} className="mr-3 bg-dark-green text-light-green font-bold px-4 py-2 rounded-sm">{t("Help Center")}</button>
                            <button onClick={() => window.open("https://support.earthranger.com/en_US/gundi-getting-help/gundi-contact-us", "_blank")} className="bg-light-green text-dark-green font-bold px-4 py-2 rounded-sm">{t("Contact Support")}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GundiV1Modal;