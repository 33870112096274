import React from 'react'
import Select, { components } from 'react-select'
import { NavBarSortedDown, NavBarSortedUp } from './ProjectIcons';
import { languageSelectStyle, timezoneStyle } from './componentstyles/selectinputstyles';

type SelectInputProps = {
    options: Array<any>,
    className: string,
    defaultValue?: object,
    value?: object,
    isDisabled: boolean,
    isLoading: boolean,
    isClearable: boolean,
    isSearchable: boolean,
    isMulti: boolean,
    name: string,
    onChange: Function,
    onMenuScrollToBottom?: any,
    handleInputChange?: any,
    languageSelect?: boolean,
    timezoneSelect?: boolean
}

const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
        <div ref={innerRef} {...innerProps} className='p-3 flex flex-col hover:bg-light-blue w-full whitespace-nowrap'>
            <div className='text-off-black'>{data.label}</div>
            <div className='text-secondary-gray'>{data.subtitle}</div>
        </div>
    );
};

const CustomSingleValue = (props: any) => {
    const { data } = props;
    return (
        <components.SingleValue {...props}>
            <span>{data.label}</span>
        </components.SingleValue>
    );
};

const SelectInput = ({
    options,
    className,
    defaultValue,
    value,
    isDisabled,
    isLoading,
    isClearable,
    isSearchable,
    isMulti,
    name,
    onChange,
    onMenuScrollToBottom,
    handleInputChange,
    languageSelect,
    timezoneSelect
}: SelectInputProps) => {
    const DropdownIndicator = (props: any) => {
        const { selectProps } = props;
        return (
            <components.DropdownIndicator {...props}>
                {selectProps.menuIsOpen ? <NavBarSortedUp /> : <NavBarSortedDown />}
            </components.DropdownIndicator>
        );
    };

    const styles = timezoneSelect ? timezoneStyle : languageSelect ? languageSelectStyle : {};

    // Function to reorder options
    const reorderOptions = (options: any[], selectedValue: any) => {
        if (!selectedValue) return options;
        const selectedIndex = options.findIndex(option => option.label === selectedValue.label);
        if (selectedIndex === -1) return options;
        const [selectedOption] = options.splice(selectedIndex, 1);
        return [selectedOption, ...options];
    };

    // Reorder options based on the selected value
    const reorderedOptions = reorderOptions([...options], value);

    return (
        <Select
            options={reorderedOptions} // Use reordered options here
            className={className}
            defaultValue={defaultValue}
            value={value}
            isDisabled={isDisabled}
            isLoading={isLoading}
            isClearable={isClearable}
            isSearchable={isSearchable}
            isMulti={isMulti}
            name={name}
            onChange={(e) => onChange(e)}
            onMenuScrollToBottom={onMenuScrollToBottom}
            onInputChange={handleInputChange}
            styles={styles}
            components={{
                ...(languageSelect || timezoneSelect ? { DropdownIndicator } : {}),
                ...(timezoneSelect ? { Option: CustomOption, SingleValue: CustomSingleValue } : {})
            }}
            filterOption={(option: any, input) => {
                if (!input) return true;
                const lowerCaseInput = input.toLowerCase().trim();
                const searchableFields = [
                    option.label.toLowerCase(),
                    ...(option.data.searchTerms || [])
                ];

                return searchableFields.some(field =>
                    field.includes(lowerCaseInput) ||
                    lowerCaseInput.split(" ").every((word: string) => field.includes(word))
                );
            }}
        />
    );
};

export default SelectInput;